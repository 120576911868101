<template>
  <div class="surface-section px-4 pb-8 md:px-6 lg:px-8 text-center flex flex-column align-items-center">
    <div class="banner-image">
        <img style="width: 100%;" alt="pago exitoso" :src="publicPath + 'images/payment/payment_success.gif'" />
    </div>
    <p class="text-2xl green-text">¡El pago se ha realizado correctamente!</p>
    <p class="text-lg">
      Los productos pueden tardar en activarse unos minutos. <br> Gracias por confiar
      en nosotros.
    </p>
    <router-link to="/"><i class="pi pi-fw pi-home"></i> Ir al Dashboard</router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  }
};
</script>

<style lang="scss" scoped>
.green-text {
  color: #ABB763;
}

.banner-image {
    width: min(80vw, 640px );
}
</style>
